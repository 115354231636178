import "./about.css"
import "../HomePage/home.css"
import {useScrollContext} from "../../components/SmoothScroll/scroll-context";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";

export const AboutPage = () => {
  const { updateScrollData, previous, current, rounding } = useScrollContext();

  useEffect(() => {
    updateScrollData({
      previous,
      current,
      rounding,
      ease: isMobile ? 1 : 0.1,
    })
  }, [])

  const [style, setStyle] = useState({});

  useEffect(() => {
    const translateY = `${previous / 2}px`; // Adjust the divisor to control the speed of the vertical movement

    setStyle({
      background: {
        transform: `translateY(${translateY})`,
      }
    })
  }, [previous])

  return (
    <div className="home-container">
      <div className="home-background" style={{backgroundColor: "#000000"}}/>
      <div style={{display: "flex", alignItems: "center",  marginTop: 50}}>
        <img src={require("../../images/background/glow.webp")}
             style={{position: "absolute", bottom: "-10%", opacity: 1, right: "-10%", width: "60%", objectFit: "contain", zIndex: -999}}/>
        <img src={require("../../images/background/friends.png")}
             style={{position: "absolute", bottom: 145, right: 0, width: "50%", objectFit: "contain", zIndex: -99}}/>
        <div className="text-container">
          <span className="title">ABOUT US</span>
          <span className="text">
            Ever wanted a video game skilltree for real life? Ever wanted to become sung jin-woo from solo-levelling? Skilltree does exactly that, turning your life into a video game, and making self-improvement effortless.
            </span>
          <br/><br/>
          <span className="text">
            Start positive habits, feel more confident, improve your focus, achieve a new year's resolution,
            get fit, avoid burnout, or help manage ADHD. Skilltree gives you the COMPLETE guide to changing your life,
            starting from 1-minute goals all the way up to improving your relationships, building a business,
            quitting video games and social media, and improving your health.
            Turn your life into a game with levels, XP, rewards, and achievements, and compete with your friends for glory!</span>
          <span className="text">
            Millions of young people around the world struggle with motivation and not knowing how to make progress in their lives. Skilltree was founded to help people improve their mental and physical well-being, with a fun and engaging design, all for free!
            If you want to support our mission, you can get an optional a subscription to unlock TONNES of new features, or help keep our servers running with an in-app purchase. Your support means the world to us, and we're incredibly grateful to be able to help thousands of people improve their lives.</span>
        </div>
      </div>
    </div>
  )
}
